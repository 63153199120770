import _ from 'lodash'
import {showDDMMYY,showMMYY} from '../dateTime/handleDMY'
import {sortArrAsc} from '../../helper/tableList/tableList'
import { handleNumberDot } from '../cost/costStudent'

export const findItemInArrayByName =(item,data)=>{ 
    return item.findIndex(value=>(value.name===data.name&&value.unit===data.unit))
}

export const FilterUserName = (userId,arr)=>{
  let item =  arr.filter(v=>v._id===userId)
  return item[0].name
}

export const findListMaterialById =(arr,item)=>{
  return arr.filter(v=>v.idMaterial===item._id)
}

export const checkExistStudentYearShool =(arr,item)=>{
  return arr.findIndex(v=>v.fullName===item.fullName)
}

export const checkExistById = (arr,item)=>{
  return arr.findIndex(v=>v._id===item._id)
}

export const getListStudentClassByYearSchool =(arr,item)=>{
  return arr.filter(v=>v.idYearSchool===item._id)
}

export const checkExitAddNewYearSchool =(arr,item)=>{
  return arr.findIndex(v=>v.name===item.name)
}

export const checkExistNameBlockClass =(arr,data)=>{ 
  return arr.findIndex(value=>value.nameBlockClass===data.nameBlockClass)
}
export const checkExistNameClass =(arr,data)=>{ 
  return arr.findIndex(value=>value.nameClass===data.nameClass)
}

export const requireDataBlocClass = (arr,item)=>{
  return arr.findIndex(arr=>arr.idBlockClass===item._id)
}

export const findArrayById =(arr,item)=>{               
        return arr.filter(value=>value.idMaterial===item._id)
}

export const changeUpperCase=(str)=>{
  return  str!==undefined&&str!==null?str.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase()):"";
}
export const UpperCase=(str)=>{
  return str.charAt(0).toUpperCase()+str.slice(1)
}

export const SeachByValue=(arr,value)=>{           
  return arr.filter(v=>v.name.indexOf(value)!==-1)            
}
export const ReplaceDot=(value)=>{
  if(value===null){
    return 0
  }
    let result = value.toString().replace('.',',')
    let splitResult = result.split(',')
    if(splitResult[1]===undefined)return handleNumberDot(splitResult[0])
    if(splitResult[1]!==undefined)return handleNumberDot(splitResult[0])+`,${splitResult[1]}`
}

// export const handleNumberDot=(number)=>{
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
//  }

export const updateArrById = (arr,value)=>{  
  if(value._id===undefined||value._id===null)return null
  let index = arr.findIndex(item=>item._id===value._id)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return null
  }  
}
export const updateArrByIdPic = (arr,value)=>{  
  let index = arr.findIndex(item=>item.idPic===value.idPic)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return null
  }  
}

export const updateArrByIdChamCong = (arr,value)=>{  
  let index = arr.findIndex(item=>item.id===value.id)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return null
  }  
}

export const updateArrByIdTypeMaterial = (arr,value)=>{  
  return arr.filter(v=>v.idTypeMaterial!==value.idTypeMaterial)
}

export const updateArrBy_Id = (arr,value)=>{  
  let index = arr.findIndex(item=>item._id===value._id)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return {...arr}
  }  
}

export const updateArrByIdNameMaterial = (arr,value)=>{  
  let index = arr.findIndex(item=>item.idNameMaterial===value.idNameMaterial)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return {...arr}
  }  
}



export const updateArrByCode = (arr,value)=>{  
  let index = arr.findIndex(item=>item.code===value.code)
  if(index!==-1){
    let newArr =[
      ...arr.slice(0,index),
      value,
      ...arr.slice(index+1)
    ]   
    return newArr
  }else{
    return null
  }  
}

export const findIndexWhenUpdateById =(arr,v)=>{
    let newArr=''
    let index = arr.findIndex(i=>i._id===v._id)
    if(index!==-1){
    return  newArr =[
        ...arr.slice(0,index),
        v,
        ...arr.slice(index+1)
      ]
    }else{
      return ''
    }   
}

export const getArrByIdBlockClass =(arr,item)=>{
  return arr.filter(v=>v.idBlockClass===item._id)
}

export const getArrStudentClass = (arr,item)=>{ 
  return arr.filter(v=>v.idClass===item._id)
}

export const DeleteItemById = (arr,item)=>{
  return arr.filter(v=>v._id!==item._id)
}
export const DeleteItemByIdPic = (arr,item)=>{
  return arr.filter(v=>v.idPic!==item.idPic)
}

export const DeleteItemByIdNameMaterial = (arr,item)=>{
  return arr.filter(v=>v.idNameMaterial!==item.idNameMaterial)
}

export const DeleteItemByIdMaterial = (arr,item)=>{
  return arr.filter(v=>v.idMaterial!==item.idMaterial)
}

export const DeleteItemByIdQrcode = (arr,item)=>{
  return arr.filter(v=>v.id!==item.id)
}

export const CheckExistSumLessMoreAferAdd = (arr,item)=>{
 return arr.some(v=>(v.userId===item.userId&&
                showDDMMYY(v.dateMeal)===showDDMMYY(item.dateMeal)
    ))
}

export const CheckExistItemBalanceToAdd = (arr,item)=>{
  return arr.some(v=>(v.userId===item.userId&&
                  v.idTypeMaterial===item.idTypeMaterial&&
                 showMMYY(v.dateCreate)===showMMYY(item.dateCreate)
     ))
 }

 export const FindItemBalanceToEdit = (arr,user,item,date)=>{
  let Result =  arr.filter(v=>(v.userId===user&&
                      v.idTypeMaterial===item.idTypeMaterial&&
                 showMMYY(v.dateCreate)===showMMYY(date)
     ))    
     return Result[0]
 }

export const FindValueNearest = (arr,dateInput)=>{    
  let newArr = sortArrAsc(arr) 
    newArr = arr.filter(v=>showDDMMYY(v.dateMeal)===showDDMMYY(dateInput))
  //xac dinh vi tri phan tu trong mang
  let findIndex = _.findIndex(arr,function(i){
    return   newArr.length>0?i._id ===newArr[0]._id:null
  })
  return arr[findIndex-1]
}

export const FindItemSumLessMoreByDateInput = (arr,dateInput)=>{
   let newArr = arr.filter(v=>showDDMMYY(v.dateMeal)===showDDMMYY(dateInput))
   return newArr.length>0?newArr[0]:""
}

export const DocTienBangChu = function () {
    this.ChuSo = new Array(" không ", " một ", " hai ", " ba ", " bốn ", " năm ", " sáu ", " bảy ", " tám ", " chín ");
    this.Tien = new Array("", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ");
  };
  DocTienBangChu.prototype.docSo3ChuSo = function (baso) {
    var tram;
      var chuc;
      var donvi;
      var KetQua = "";
      tram = parseInt(baso / 100);
      chuc = parseInt((baso % 100) / 10);
      donvi = baso % 10;
      if (tram === 0 && chuc === 0 && donvi === 0) return "";
      if (tram !== 0) {
          KetQua += this.ChuSo[tram] + " trăm ";
          if ((chuc === 0) && (donvi !== 0)) KetQua += " linh ";
      }
      if ((chuc !== 0) && (chuc !== 1)) { 
          KetQua += this.ChuSo[chuc] + " mươi ";
          if ((chuc === 0) && (donvi !== 0)) KetQua = KetQua + " linh ";
      }
      if (chuc === 1) KetQua += " mười ";
      switch (donvi) {
          case 1:
              if ((chuc !== 0) && (chuc !== 1)) {
                  KetQua += " mốt ";
              }
              else {
                  KetQua += this.ChuSo[donvi];
              }
              break;
          case 5:
              if (chuc === 0) {
                  KetQua += this.ChuSo[donvi];
              }
              else {
                  KetQua += " lăm ";
              }
              break;
          default:
              if (donvi !== 0) {
                  KetQua += this.ChuSo[donvi];
              }
              break;
      }
      return KetQua;
  }
  
  DocTienBangChu.prototype.doc = function (SoTien) {
    var lan = 0;
      var i = 0;
      var so = 0;
      var KetQua = "";
      var tmp = "";
      var soAm = false;
      var ViTri = new Array();
      if (SoTien < 0) soAm = true;//return "Số tiền âm !";
      if (SoTien === 0) return "Không đồng";//"Không đồng !";
      if (SoTien > 0) {
          so = SoTien;
      }
      else {
          so = -SoTien;
      }
      if (SoTien > 8999999999999999) {
          //SoTien = 0;
          return "";//"Số quá lớn!";
      }
      ViTri[5] = Math.floor(so / 1000000000000000);
      if (isNaN(ViTri[5]))
          ViTri[5] = "0";
      so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
      ViTri[4] = Math.floor(so / 1000000000000);
      if (isNaN(ViTri[4]))
          ViTri[4] = "0";
      so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
      ViTri[3] = Math.floor(so / 1000000000);
      if (isNaN(ViTri[3]))
          ViTri[3] = "0";
      so = so - parseFloat(ViTri[3].toString()) * 1000000000;
      ViTri[2] = parseInt(so / 1000000);
      if (isNaN(ViTri[2]))
          ViTri[2] = "0";
      ViTri[1] = parseInt((so % 1000000) / 1000);
      if (isNaN(ViTri[1]))
          ViTri[1] = "0";
      ViTri[0] = parseInt(so % 1000);
      if (isNaN(ViTri[0]))
          ViTri[0] = "0";
      if (ViTri[5] > 0) {
          lan = 5;
      }
      else if (ViTri[4] > 0) {
          lan = 4;
      }
      else if (ViTri[3] > 0) {
          lan = 3;
      }
      else if (ViTri[2] > 0) {
          lan = 2;
      }
      else if (ViTri[1] > 0) {
          lan = 1;
      }
      else {
          lan = 0;
      }
      for (i = lan; i >= 0; i--) {
          tmp = this.docSo3ChuSo(ViTri[i]);
          KetQua += tmp;
          if (ViTri[i] > 0) KetQua += this.Tien[i];
          if ((i > 0) && (tmp.length > 0)) KetQua += '';//',';//&& (!string.IsNullOrEmpty(tmp))
      }
      if (KetQua.substring(KetQua.length - 1) === ',') {
          KetQua = KetQua.substring(0, KetQua.length - 1);
      }
      KetQua = KetQua.substring(1, 2).toUpperCase() + KetQua.substring(2);
      if (soAm) {
          return "Âm " + KetQua + " đồng";//.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
      }
      else {
          return KetQua + " đồng";//.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
      }
  }

  export const FilterIdTypeMaterial = (arr,item)=>{   
    return arr.filter(v=>v.idTypeMaterial===item.idTypeMaterial)
  }

  export const CheckShowEditTrueOrFalse =(arr)=>{
     let find = arr.some(v=>v.edit===true)
     return find
  }

  export const convertToTime=(timeStamp)=>{
     return new Date(timeStamp).toLocaleString("vi-VN", {timeZone: "Asia/Saigon"})     
  }



  export const getLocaleDateString=()=>{
    let formats = {
      "af-ZA": "yyyy/MM/dd",
      "am-ET": "d/M/yyyy",
      "ar-AE": "dd/MM/yyyy",
      "ar-BH": "dd/MM/yyyy",
      "ar-DZ": "dd-MM-yyyy",
      "ar-EG": "dd/MM/yyyy",
      "ar-IQ": "dd/MM/yyyy",
      "ar-JO": "dd/MM/yyyy",
      "ar-KW": "dd/MM/yyyy",
      "ar-LB": "dd/MM/yyyy",
      "ar-LY": "dd/MM/yyyy",
      "ar-MA": "dd-MM-yyyy",
      "ar-OM": "dd/MM/yyyy",
      "ar-QA": "dd/MM/yyyy",
      "ar-SA": "dd/MM/yy",
      "ar-SY": "dd/MM/yyyy",
      "ar-TN": "dd-MM-yyyy",
      "ar-YE": "dd/MM/yyyy",
      "arn-CL": "dd-MM-yyyy",
      "as-IN": "dd-MM-yyyy",
      "az-Cyrl-AZ": "dd.MM.yyyy",
      "az-Latn-AZ": "dd.MM.yyyy",
      "ba-RU": "dd.MM.yy",
      "be-BY": "dd.MM.yyyy",
      "bg-BG": "dd.M.yyyy",
      "bn-BD": "dd-MM-yy",
      "bn-IN": "dd-MM-yy",
      "bo-CN": "yyyy/M/d",
      "br-FR": "dd/MM/yyyy",
      "bs-Cyrl-BA": "d.M.yyyy",
      "bs-Latn-BA": "d.M.yyyy",
      "ca-ES": "dd/MM/yyyy",
      "co-FR": "dd/MM/yyyy",
      "cs-CZ": "d.M.yyyy",
      "cy-GB": "dd/MM/yyyy",
      "da-DK": "dd-MM-yyyy",
      "de-AT": "dd.MM.yyyy",
      "de-CH": "dd.MM.yyyy",
      "de-DE": "dd.MM.yyyy",
      "de-LI": "dd.MM.yyyy",
      "de-LU": "dd.MM.yyyy",
      "dsb-DE": "d. M. yyyy",
      "dv-MV": "dd/MM/yy",
      "el-GR": "d/M/yyyy",
      "en-029": "MM/dd/yyyy",
      "en-AU": "d/MM/yyyy",
      "en-BZ": "dd/MM/yyyy",
      "en-CA": "dd/MM/yyyy",
      "en-GB": "dd/MM/yyyy",
      "en-IE": "dd/MM/yyyy",
      "en-IN": "dd-MM-yyyy",
      "en-JM": "dd/MM/yyyy",
      "en-MY": "d/M/yyyy",
      "en-NZ": "d/MM/yyyy",
      "en-PH": "M/d/yyyy",
      "en-SG": "d/M/yyyy",
      "en-TT": "dd/MM/yyyy",
      "en-US": "M/d/yyyy",
      "en-ZA": "yyyy/MM/dd",
      "en-ZW": "M/d/yyyy",
      "es-AR": "dd/MM/yyyy",
      "es-BO": "dd/MM/yyyy",
      "es-CL": "dd-MM-yyyy",
      "es-CO": "dd/MM/yyyy",
      "es-CR": "dd/MM/yyyy",
      "es-DO": "dd/MM/yyyy",
      "es-EC": "dd/MM/yyyy",
      "es-ES": "dd/MM/yyyy",
      "es-GT": "dd/MM/yyyy",
      "es-HN": "dd/MM/yyyy",
      "es-MX": "dd/MM/yyyy",
      "es-NI": "dd/MM/yyyy",
      "es-PA": "MM/dd/yyyy",
      "es-PE": "dd/MM/yyyy",
      "es-PR": "dd/MM/yyyy",
      "es-PY": "dd/MM/yyyy",
      "es-SV": "dd/MM/yyyy",
      "es-US": "M/d/yyyy",
      "es-UY": "dd/MM/yyyy",
      "es-VE": "dd/MM/yyyy",
      "et-EE": "d.MM.yyyy",
      "eu-ES": "yyyy/MM/dd",
      "fa-IR": "MM/dd/yyyy",
      "fi-FI": "d.M.yyyy",
      "fil-PH": "M/d/yyyy",
      "fo-FO": "dd-MM-yyyy",
      "fr-BE": "d/MM/yyyy",
      "fr-CA": "yyyy-MM-dd",
      "fr-CH": "dd.MM.yyyy",
      "fr-FR": "dd/MM/yyyy",
      "fr-LU": "dd/MM/yyyy",
      "fr-MC": "dd/MM/yyyy",
      "fy-NL": "d-M-yyyy",
      "ga-IE": "dd/MM/yyyy",
      "gd-GB": "dd/MM/yyyy",
      "gl-ES": "dd/MM/yy",
      "gsw-FR": "dd/MM/yyyy",
      "gu-IN": "dd-MM-yy",
      "ha-Latn-NG": "d/M/yyyy",
      "he-IL": "dd/MM/yyyy",
      "hi-IN": "dd-MM-yyyy",
      "hr-BA": "d.M.yyyy.",
      "hr-HR": "d.M.yyyy",
      "hsb-DE": "d. M. yyyy",
      "hu-HU": "yyyy. MM. dd.",
      "hy-AM": "dd.MM.yyyy",
      "id-ID": "dd/MM/yyyy",
      "ig-NG": "d/M/yyyy",
      "ii-CN": "yyyy/M/d",
      "is-IS": "d.M.yyyy",
      "it-CH": "dd.MM.yyyy",
      "it-IT": "dd/MM/yyyy",
      "iu-Cans-CA": "d/M/yyyy",
      "iu-Latn-CA": "d/MM/yyyy",
      "ja-JP": "yyyy/MM/dd",
      "ka-GE": "dd.MM.yyyy",
      "kk-KZ": "dd.MM.yyyy",
      "kl-GL": "dd-MM-yyyy",
      "km-KH": "yyyy-MM-dd",
      "kn-IN": "dd-MM-yy",
      "ko-KR": "yyyy. MM. dd",
      "kok-IN": "dd-MM-yyyy",
      "ky-KG": "dd.MM.yy",
      "lb-LU": "dd/MM/yyyy",
      "lo-LA": "dd/MM/yyyy",
      "lt-LT": "yyyy.MM.dd",
      "lv-LV": "yyyy.MM.dd.",
      "mi-NZ": "dd/MM/yyyy",
      "mk-MK": "dd.MM.yyyy",
      "ml-IN": "dd-MM-yy",
      "mn-MN": "yy.MM.dd",
      "mn-Mong-CN": "yyyy/M/d",
      "moh-CA": "M/d/yyyy",
      "mr-IN": "dd-MM-yyyy",
      "ms-BN": "dd/MM/yyyy",
      "ms-MY": "dd/MM/yyyy",
      "mt-MT": "dd/MM/yyyy",
      "nb-NO": "dd.MM.yyyy",
      "ne-NP": "M/d/yyyy",
      "nl-BE": "d/MM/yyyy",
      "nl-NL": "d-M-yyyy",
      "nn-NO": "dd.MM.yyyy",
      "nso-ZA": "yyyy/MM/dd",
      "oc-FR": "dd/MM/yyyy",
      "or-IN": "dd-MM-yy",
      "pa-IN": "dd-MM-yy",
      "pl-PL": "dd.MM.yyyy",
      "prs-AF": "dd/MM/yy",
      "ps-AF": "dd/MM/yy",
      "pt-BR": "d/M/yyyy",
      "pt-PT": "dd-MM-yyyy",
      "qut-GT": "dd/MM/yyyy",
      "quz-BO": "dd/MM/yyyy",
      "quz-EC": "dd/MM/yyyy",
      "quz-PE": "dd/MM/yyyy",
      "rm-CH": "dd/MM/yyyy",
      "ro-RO": "dd.MM.yyyy",
      "ru-RU": "dd.MM.yyyy",
      "rw-RW": "M/d/yyyy",
      "sa-IN": "dd-MM-yyyy",
      "sah-RU": "MM.dd.yyyy",
      "se-FI": "d.M.yyyy",
      "se-NO": "dd.MM.yyyy",
      "se-SE": "yyyy-MM-dd",
      "si-LK": "yyyy-MM-dd",
      "sk-SK": "d. M. yyyy",
      "sl-SI": "d.M.yyyy",
      "sma-NO": "dd.MM.yyyy",
      "sma-SE": "yyyy-MM-dd",
      "smj-NO": "dd.MM.yyyy",
      "smj-SE": "yyyy-MM-dd",
      "smn-FI": "d.M.yyyy",
      "sms-FI": "d.M.yyyy",
      "sq-AL": "yyyy-MM-dd",
      "sr-Cyrl-BA": "d.M.yyyy",
      "sr-Cyrl-CS": "d.M.yyyy",
      "sr-Cyrl-ME": "d.M.yyyy",
      "sr-Cyrl-RS": "d.M.yyyy",
      "sr-Latn-BA": "d.M.yyyy",
      "sr-Latn-CS": "d.M.yyyy",
      "sr-Latn-ME": "d.M.yyyy",
      "sr-Latn-RS": "d.M.yyyy",
      "sv-FI": "d.M.yyyy",
      "sv-SE": "yyyy-MM-dd",
      "sw-KE": "M/d/yyyy",
      "syr-SY": "dd/MM/yyyy",
      "ta-IN": "dd-MM-yyyy",
      "te-IN": "dd-MM-yy",
      "tg-Cyrl-TJ": "dd.MM.yy",
      "th-TH": "d/M/yyyy",
      "tk-TM": "dd.MM.yy",
      "tn-ZA": "yyyy/MM/dd",
      "tr-TR": "dd.MM.yyyy",
      "tt-RU": "dd.MM.yyyy",
      "tzm-Latn-DZ": "dd-MM-yyyy",
      "ug-CN": "yyyy-M-d",
      "uk-UA": "dd.MM.yyyy",
      "ur-PK": "dd/MM/yyyy",
      "uz-Cyrl-UZ": "dd.MM.yyyy",
      "uz-Latn-UZ": "dd/MM yyyy",
      "vi-VN": "dd/MM/yyyy",
      "wo-SN": "dd/MM/yyyy",
      "xh-ZA": "yyyy/MM/dd",
      "yo-NG": "d/M/yyyy",
      "zh-CN": "yyyy/M/d",
      "zh-HK": "d/M/yyyy",
      "zh-MO": "d/M/yyyy",
      "zh-SG": "d/M/yyyy",
      "zh-TW": "yyyy/M/d",
      "zu-ZA": "yyyy/MM/dd",
    };  
    return formats[navigator.language] || "dd/MM/yyyy";
  }

  export const convertTimeToSecond=(time)=>{
    return (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);
  }

  export const ValidateEmailAddress=(emailString)=> {
    // check for @ sign
    // if(emailString==="")return false
    // var atSymbol = emailString.indexOf("@");
    // if(atSymbol < 1) return false;
    
    // var dot = emailString.indexOf(".");
    // if(dot <= atSymbol + 2) return false;
    
    // // check that the dot is not at the end
    // if (dot === emailString.length - 1) return false;
    
    // return true;
    //let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    let validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(emailString.match(validRegex)) return true
    return false
}

export const ValidTel=(tel)=>{
  if(tel==="")return false
  let check = /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/
  if(tel.match(check)) return true
  return false
}

//tim so nho nhat
const  minElement=(array)=>{
  let min =  array[0].numberEatLess;
  let min_index= 0;   
  /*So sánh từng số trong mảng với giá trị đầu tiên để tìm ra giá trị nhỏ nhất*/
  for (let i = 1; i < array.length; ++i) {
      if (min > array[i].numberEatLess) { //Thay đổi giá trị nhỏ nhất nếu tìm ra số nhỏ hơn
          min = array[i].numberEatLess;
          min_index =i;
      }
  }
  console.log("min= ",min);
  console.log("min_index= ",min_index);
  return min_index
}