export const ADD_BLOCK                        = "ADD_BLOCK"
export const ADD_BLOCK_TO_STORE               = "ADD_BLOCK_TO_STORE"
export const ADD_BLOCK_SUCCESS                = "ADD_BLOCK_SUCCESS"
export const ADD_CLASS                        = "ADD_CLASS"
export const ADD_CLASS_SUCCESS                = "ADD_CLASS_SUCCESS"

export const DISPATCH_ADD_CLASS_TO_STORE      = "DISPATCH_ADD_CLASS_TO_STORE"
export const DISPATCH_LIST_CLASS_TO_STORE     = "DISPATCH_LIST_CLASS_TO_STORE"

export const LIST_CLASS                       = "LIST_CLASS"
export const LIST_BLOCK                       = "LIST_BLOCK"
export const LIST_TABLE_BLOCK                 = "LIST_TABLE_BLOCK"
export const EDIT_BLOCK                       = "EDIT_BLOCK"
export const UPDATE_BLOCK_TO_STORE            = "UPDATE_BLOCK_TO_STORE"
export const DISPATCH_ROW_BLOCK_TO_STORE      = "DISPATCH_ROW_BLOCK_TO_STORE"
 
export const EDIT_CLASS                       = "EDIT_CLASS"
export const DELETE_CLASS                     = "DELETE_CLASS"

//export const DISPATCH_OPEN_LIST_CLASS_STUDENT = "DISPATCH_OPEN_LIST_CLASS_STUDENT"
export const DISPATCH_ROW_CLASS_STUDENT                 = "DISPATCH_ROW_CLASS_STUDENT" 

export const ADD_STUDENT                              = "ADD_STUDENT"
export const ADD_STUDENT_AFTER                        = "ADD_STUDENT_AFTER"
export const LIST_ALL_STUDENT                         = "LIST_ALL_STUDENT"
export const DISPATCH_LIST_ALL_STUDENT_TO_STORE       = "DISPATCH_LIST_ALL_STUDENT_TO_STORE" 
export const DISPATCH_ADD_STUDENT_TO_STORE            = "DISPATCH_ADD_STUDENT_TO_STORE"
export const ADD_STUDENT_STUDENT_NOW                  = "ADD_STUDENT_STUDENT_NOW"
export const DISPATCH_STUDENT_STUDENT_NOW_TO_STORE    = "DISPATCH_STUDENT_STUDENT_NOW_TO_STORE"
export const DISPATCH_SAVE_LIST_STUDENT_NOW_TO_SAGA   = "DISPATCH_SAVE_LIST_STUDENT_NOW_TO_SAGA"
export const SET_VALUE_UPDATE_OR_ADD_LIST_STUDENT_NOW = "SET_VALUE_UPDATE_OR_ADD_LIST_STUDENT_NOW"
export const DISPATCH_UPDATE_ITEM_STUDENT_NOW         = "DISPATCH_UPDATE_ITEM_STUDENT_NOW"
export const DISPATCH_UPDATE_ARR_STUDENT_NOW          = "DISPATCH_UPDATE_ARR_STUDENT_NOW"
export const DELETE_ITEM_STUDENT_NOW                  = "DELETE_ITEM_STUDENT_NOW"


export const CLOSE_FORM_STUDENT                       = "CLOSE_FORM_STUDENT"
export const OPEN_FORM_IMPORT_EXCEL                   = "OPEN_FORM_IMPORT_EXCEL"
export const UPLOAD_FILE_EXCEL                        = "UPLOAD_FILE_EXCEL"
export const CLOSE_FORM_IMPORT_EXCEL                  = "CLOSE_FORM_IMPORT_EXCEL"
export const OPEN_FORM_STUDENT                        = "OPEN_FORM_STUDENT"
export const UPDATE_ARR_AFTER_UPLOAD_EXCEL            = "UPDATE_ARR_AFTER_UPLOAD_EXCEL"

//YearSchool

//add form va list form
export const DISPATCH_LIST_YEAR_SCHOOL                = "DISPATCH_LIST_YEAR_SCHOOL"
export const LIST_ALL_YEAR_SCHOOL                     = "LIST_ALL_YEAR_SCHOOL"
export const OPEN_FORM_ADD_YEAR_SCHOOL                = "OPEN_FORM_ADD_YEAR_SCHOOL"
export const CLOSE_FORM_ADD_YEAR_SCHOOL               = "CLOSE_FORM_ADD_YEAR_SCHOOL"
export const OPEN_ADD_YEAR_SCHOOL                     = "OPEN_ADD_YEAR_SCHOOL"
export const CLOSE_ADD_YEAR_SCHOOL                    = "CLOSE_ADD_YEAR_SCHOOL"
export const ADD_YEAR_SCHOOL                          = "ADD_YEAR_SCHOOL"
export const ADD_YEAR_SCHOOL_SUCCESS                  = "ADD_YEAR_SCHOOL_SUCCESS"
export const DISPATCH_ADD_YEAR_SCHOOL_TO_STORE        = "DISPATCH_ADD_YEAR_SCHOOL_TO_STORE"
export const DISPATCH_LIST_YEAR_SCHOOL_TO_STORE       = "DISPATCH_LIST_YEAR_SCHOOL_TO_STORE"

//select form
export const OPEN_FORM_SELECT                         = "OPEN_FORM_SELECT"
export const CLOSE_FORM_SELECT                        = "CLOSE_FORM_SELECT"
export const DISPATCH_CREATE_YEAR_SCHOOL              = "DISPATCH_CREATE_YEAR_SCHOOL"

export const OPEN_EDIT_YEAR_SCHOOL                    = "OPEN_EDIT_YEAR_SCHOOL"
export const CLOSE_EDIT_YEAR_SCHOOL                   = "CLOSE_EDIT_YEAR_SCHOOL"
export const EDIT_YEAR_SCHOOL                         = "EDIT_YEAR_SCHOOL"

export const OPEN_EDIT_ROW_STUDENT_YEAR_SCHOOL        = "OPEN_EDIT_ROW_STUDENT_YEAR_SCHOOL"
export const CLOSE_EDIT_ROW_STUDENT_YEAR_SCHOOL       = "CLOSE_EDIT_ROW_STUDENT_YEAR_SCHOOL"
export const EDIT_STUDENT_YEAR_SCHOOL                 = "EDIT_STUDENT_YEAR_SCHOOL"
export const DELETE_STUDENT_YEAR_SCHOOL               = "DELETE_STUDENT_YEAR_SCHOOL"

export const OPEN_FORM_ALERT                          = "OPEN_FORM_ALERT" //xoa
export const CLOSE_FORM_ALERT                         = "CLOSE_FORM_ALERT" //xoa

export const OPEN_FORM_ALERT_EDIT                     = "OPEN_FORM_ALERT_EDIT" //edit
export const CLOSE_FORM_ALERT_EDIT                    = "CLOSE_FORM_ALERT_EDIT" //edit

export const OPEN_FORM_ALERT_ADD                     = "OPEN_FORM_ALERT_ADD" //add
export const CLOSE_FORM_ALERT_ADD                    = "CLOSE_FORM_ALERT_ADD" // add

//check day food
export const OPEN_CHECK_DAY_FOOD                           = "OPEN_CHECK_DAY_FOOD" 
export const CLOSE_CHECK_DAY_FOOD                          = "CLOSE_CHECK_DAY_FOOD" 
export const LIST_ALL_DATE_MEAL                            = "LIST_ALL_DATE_MEAL"
export const DISPATCH_LIST_ALL_DATE_MEAL                   = "DISPATCH_LIST_ALL_DATE_MEAL"
export const OPEN_FORM_ALERT_ADD_DATE_MEAL                 = "OPEN_FORM_ALERT_ADD_DATE_MEAL"
export const CLOSE_FORM_ALERT_ADD_DATE_MEAL                = "CLOSE_FORM_ALERT_ADD_DATE_MEAL"
export const OPEN_FORM_ADD_DATE_MEAL                       = "OPEN_FORM_ADD_DATE_MEAL"
export const CLOSE_FORM_ADD_DATE_MEAL                      = "CLOSE_FORM_ADD_DATE_MEAL"
export const DATE_NOW                                      = "DATE_NOW"
export const DISPATCH_ADD_DATE_MEAL_TO_STORE               = "DISPATCH_ADD_DATE_MEAL_TO_STORE"
export const ADD_CHECK_DATE_FOOD                           = "ADD_CHECK_DATE_FOOD"
export const DISPATCH_ADD_MANY_CHECK_DATE_FOOD             = "DISPATCH_ADD_MANY_CHECK_DATE_FOOD"

export const LIST_CHECK_DATE_FOOD                           = "LIST_CHECK_DATE_FOOD"
export const DISPATCH_ADD_CHECK_DATE_FOOD                   = "DISPATCH_ADD_CHECK_DATE_FOOD"
export const DISPATCH_LIST_CHECK_DATE_FOOD                  = "DISPATCH_LIST_CHECK_DATE_FOOD"
//check day food
export  const ALERT_OPEN_ADD_EMPTY_CHECK_DAY_FOOD           = "ALERT_OPEN_ADD_EMPTY_CHECK_DAY_FOOD"
export const ALERT_CLOSE_ADD_EMPTY_CHECK_DAY_FOOD           = "ALERT_CLOSE_ADD_EMPTY_CHECK_DAY_FOOD"
export const DUPLICATED_OPEN_ALERT_EMPTY_CHECK_DAY_FOOD     = "DUPLICATED_OPEN_ALERT_EMPTY_CHECK_DAY_FOOD"
export const DUPLICATED_CLOSE_ALERT_EMPTY_CHECK_DAY_FOOD    = "DUPLICATED_CLOSE_ALERT_EMPTY_CHECK_DAY_FOOD"
export const VIEW_OPEN_CHECK_DAY_FOOD                       = "VIEW_OPEN_CHECK_DAY_FOOD"
export const VIEW_CLOSE_CHECK_DAY_FOOD                      = "VIEW_CLOSE_CHECK_DAY_FOOD"
export const DISPATCH_DATE_CHECKFOOD                        = "DISPATCH_DATE_CHECK_FOOD"
export const DISPATCH_NUMBER_STUDENT_NOT_EAT                = "DISPATCH_NUMBER_STUDENT_NOT_EAT"
export const DISPATCH_ITEM_STUDENT_DATE_INPUT               = "DISPATCH_ITEM_STUDENT_DATE_INPUT"
export const UPDATE_ITEM_STUDENT_CHECK_DATE_FOOD            = "UPDATE_ITEM_STUDENT_CHECK_DATE_FOOD"
export const UPDATE_CHECK_DATE_FOOD_SUCCESS                 = "ADD_YEAR_SCHOOL_SUCCESS"
export const DELETE_ITEM_CHECK_DATE_FOOD                    = "DELETE_ITEM_CHECK_DATE_FOOD"

export const OPEN_CHECK_DAY_FOOD_AGAIN                      = "OPEN_CHECK_DAY_FOOD_AGAIN"
export const CLOSE_CHECK_DAY_FOOD_AGAIN                     = "CLOSE_CHECK_DAY_FOOD_AGAIN"
export const SEND_IDS_TO_DB_ADD_EMPTY_NOT_EAT               = "SEND_IDS_TO_DB_ADD_EMPTY_NOT_EAT"

export const DISPATCH_ARRAY_STUDENT_NOT_EAT                = "DISPATCH_ARRAY_STUDENT_NOT_EAT"

export const DISPACTH_DELETE_STUDENT_ARR                   = "DISPACTH_DELETE_STUDENT_ARR"

export const REGIS_SEVEN_DAY_TRUE                          = "REGIS_SEVEN_DAY_TRUE"
export const REGIS_SEVEN_DAY_FALSE                         = "REGIS_SEVEN_DAY_FALSE"
export const OPEN_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY       = "OPEN_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY"
export const CLOSE_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY      = "CLOSE_ALERT_EMPTY_SELECT_REGIS_SEVEN_DAY"
export const DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY          = "DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY"
export const DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY_TO_STORE = "DISPATCH_ADD_STUDENT_REGIS_SEVEN_DAY_TO_STORE"
export const LIST_REGIS_SEVEN                              = "LIST_REGIS_SEVEN"
export const DISPATCH_LIST_REGIS_SEVEN_TO_STORE            = "DISPATCH_LIST_REGIS_SEVEN_TO_STORE"

export const OPEN_UPDATE_REGIS_SEVEN_DAY                   = "OPEN_UPDATE_REGIS_SEVEN_DAY"
export const CLOSE_UPDATE_REGIS_SEVEN_DAY                  = "CLOSE_UPDATE_REGIS_SEVEN_DAY"
export const DISPATCH_ARR_TO_UPDATE_REGIS_SEVEN_DAY        = "DISPATCH_ARR_TO_UPDATE_REGIS_SEVEN_DAY"
export const DISPATCH_UPDATE_REGIS_SEVEN_DAY               = "DISPATCH_UPDATE_REGIS_SEVEN_DAY"
export const UPDATE_REGIS_SEVEN_DAY_SUCCESS                = "UPDATE_REGIS_SEVEN_DAY_SUCCESS"
export const DELETE_ITEM_REGIS_SEVEN_DAY                   = "DELETE_ITEM_REGIS_SEVEN_DAY"

export const OPEN_ALERT_DELETE_REGIS_SEVEN_DAY             = "OPEN_ALERT_DELETE_REGIS_SEVEN_DAY"
export const CLOSE_ALERT_DELETE_REGIS_SEVEN_DAY            = "CLOSE_ALERT_DELETE_REGIS_SEVEN_DAY"

export const OPEN_ALERT_DELETE_CHECK_DATE_FOOD                    = "OPEN_ALERT_DELETE_CHECK_DATE_FOOD"
export const CLOSE_ALERT_DELETE_CHECK_DATE_FOOD                   = "CLOSE_ALERT_DELETE_CHECK_DATE_FOOD"

export const DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW = "DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW"
export const DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW_LAST = "DISPATCH_LIST_STUDENT_CLASS_BY_YEAR_SCHOOL_DB_LIST_STUENT_NOW_LAST"
export const LIST_STUDENT_NOW                                     = "LIST_STUDENT_NOW"
export const DISPATCH_ADD_LIST_STUDENT_NOW_TO_STORE               = "DISPATCH_ADD_LIST_STUDENT_NOW_TO_STORE"
export const ALERT_ADD_STUDENT_NOW_OPEN_FORM                      = "ALERT_ADD_STUDENT_NOW_OPEN_FORM"      
export const ALERT_ADD_STUDENT_NOW_CLOSE_FORM                     = "ALERT_ADD_STUDENT_NOW_CLOSE_FORM"      
export const DISPATCH_ALL_LIST_STUDENT_NOW                        = "DISPATCH_ALL_LIST_STUDENT_NOW"
export const ALL_LIST_STUDENT_NOW                                 = "ALL_LIST_STUDENT_NOW"
export const DISPATCH_LIST_STUDENT_IN_CLASS_IN_LAST_MONTH_TO_STORE = "DISPATCH_LIST_STUDENT_IN_CLASS_IN_LAST_MONTH_TO_STORE"
export const DISPATCH_ADD_STUDENT_IN_CLASS_TO_STORE               = "DISPATCH_ADD_STUDENT_IN_CLASS_TO_STORE"
export const OPEN_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH               = "OPEN_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH"
export const CLOSE_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH              = "CLOSE_FORM_TO_ADD_NEW_STUDENT_NEW_MONTH"
export const ALERT_OPEN_ADD_STUDENT_NOW_AGAIN                     = "ALERT_OPEN_ADD_STUDENT_NOW_AGAIN"
export const ALERT_CLOSE_ADD_STUDENT_NOW_AGAIN                    = "ALERT_CLOSE_ADD_STUDENT_NOW_AGAIN"
export const OPEN_LIST_VIEW_REGIS_SEVEN_DAY                       = "OPEN_LIST_VIEW_REGIS_SEVEN_DAY"
export const CLOSE_LIST_VIEW_REGIS_SEVEN_DAY                      = "CLOSE_LIST_VIEW_REGIS_SEVEN_DAY"

export const OPEN_LIST_VIEW_CHECK_DAY_FOOD                       = "OPEN_LIST_VIEW_CHECK_DAY_FOOD"
export const CLOSE_LIST_VIEW_CHECK_DAY_FOOD                      = "CLOSE_LIST_VIEW_CHECK_DAY_FOOD"

export const OPEN_BUTTON_EXPORT_PDF                              = "OPEN_BUTTON_EXPORT_PDF"
export const CLOSE_BUTTON_EXPORT_PDF                             = "CLOSE_BUTTON_EXPORT_PDF"

export const OPEN_BUTTON_EXPORT_PDF_ITEM                         = "OPEN_BUTTON_EXPORT_PDF_ITEM"
export const CLOSE_BUTTON_EXPORT_PDF_ITEM                        = "CLOSE_BUTTON_EXPORT_PDF_ITEM"

export const DELETE_ITEM_STUDENT_NOW_SUCCESS                     = "DELETE_ITEM_STUDENT_NOW_SUCCESS"


export const OPEN_UPDATE_LIST_STUDENT              = "OPEN_UPDATE_LIST_STUDENT"
export const CLOSE_UPDATE_LIST_STUDENT             = "CLOSE_UPDATE_LIST_STUDENT"
export const GET_LIST_STUDENT_BY_CLASS_YEAR_SCHOOL = "GET_LIST_STUDENT_BY_CLASS_YEAR_SCHOOL"
export const UPDATE_LIST_STUDENT                   = "UPDATE_LIST_STUDENT"
export const UPDATE_ALERT_SUCCESS_LOGOUT           = "UPDATE_ALERT_SUCCESS_LOGOUT"
export const DELETE_STUDENT_NOW                    = "DELETE_STUDENT_NOW"
export const STUDENT_LEAVE_TEMPORATY               = "STUDENT_LEAVE_TEMPORATY" 
export const GET_STUDENT_LEAVE_TEMPORATY           = "GET_STUDENT_LEAVE_TEMPORATY"
export const DISPATCH_STUDENT_LEAVE_TEMPORATY_TO_STORE = "DISPATCH_STUDENT_LEAVE_TEMPORATY_TO_STORE"
export const ADD_STUDENT_LEAVE_TEMPORATY_TO_STORE  = "ADD_STUDENT_LEAVE_TEMPORATY_TO_STORE"
export const SELECT_STUDENT_LEAVE_TEMPORATY        = "SELECT_STUDENT_LEAVE_TEMPORATY"
export const UPDATE_STUDENT_LEAVE_TEMPORATY        = "UPDATE_STUDENT_LEAVE_TEMPORATY"
export const UPDATE_STUDENT_LEAVE_TEMPORATY_AGAIN  = "UPDATE_STUDENT_LEAVE_TEMPORATY_AGAIN"
export const GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW  = "GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW"
export const GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW_TO_STORE  = "GET_STUDENT_LEAVE_TEMPORATY_NOT_BY_DATE_NOW_TO_STORE"

export const DELETE_STUDENT_YEAR_SCHOOL_IN_MANAGER_STUDENT  = "DELETE_STUDENT_YEAR_SCHOOL_IN_MANAGER_STUDENT"

export const DELETE_ITEM_STUDENT_IN_STUDENT_NOW  =  "DELETE_ITEM_STUDENT_IN_STUDENT_NOW"

export const DELETE_BLOCK_CLASS  = "DELETE_BLOCK_CLASS"

export const ADD_ALL_AUTO_STUDENT_NOW_AND_REGIS_SEVEN = "ADD_ALL_AUTO_STUDENT_NOW_AND_REGIS_SEVEN"
export const ADD_ALL_AUTO_STUDENT_NOW = "ADD_ALL_AUTO_STUDENT_NOW"