export const OPEN_SELECT_DATE_TO_COUNT_EAT      = "OPEN_SELECT_DATE_TO_COUNT_EAT"
export const CLOSE_SELECT_DATE_TO_COUNT_EAT     = "CLOSE_SELECT_DATE_TO_COUNT_EAT"
export const OPEN_FORM_SELECT_PRICE             = "OPEN_FORM_SELECT_PRICE"
export const CLOSE_FORM_SELECT_PRICE            = "CLOSE_FORM_SELECT_PRICE"
export const DISPATCH_SELECT_PRICE              = "DISPATCH_SELECT_PRICE"

//add item into material meal date
export const ADD_ITEM_MATERIAL_MEAL_DATE               = "ADD_ITEM_MATERIAL_MEAL_DATE"
export const LIST_ALL_MATERIAL_MEAL_DATE               = "LIST_ALL_MATERIAL_MEAL_DATE"
export const EDIT_MATERIAL_MEAL_DATE                   = "EDIT_MATERIAL_MEAL_DATE"
export const DISPATCH_ADD_MATERIAL_MEAL_DATE_TO_STORE       = "DISPATCH_ADD_MATERIAL_MEAT_DATE_TO_STORE"
export const DISPATCH_ADD_MANY_MATERIAL_MEAL_DATE_TO_STORE  = "DISPATCH_ADD_MANY_MATERIAL_MEAT_DATE_TO_STORE"

export const DISPATCH_LIST_ALL_MATERIAL_TO_STORE       = "DISPATCH_LIST_ALL_MATERIAL_TO_STORE"
export const DELETE_ITEM_MATERIAL_MEAL_DATE            = "DELETE_ITEM_MATERIAL_MEAL_DATE"
export const DELETE_MANY_ITEM_MATERIAL_MEAL_DATE       = "DELETE_MANY_ITEM_MATERIAL_MEAL_DATE"

export const DISPATCH_SUM_MATERIAL_MEAL_DATE_LIST      = "DISPATCH_SUM_MATERIAL_MEAL_DATE_LIST"
export const DISPATCH_SUM_BLOCK_CLASS_LIST             = "DISPATCH_SUM_BLOCK_CLASS_LIST"

//SUM LESS MORE
export const ADD_SUM_LESS_MORE                         = "ADD_SUM_LESS_MORE"
export const LIST_SUM_LESS_MORE                        = "LIST_SUM_LESS_MORE"
export const DISPATCH_ADD_SUM_LESS_MORE_TO_STORE       = "DISPATCH_ADD_SUM_LESS_MORE_TO_STORE"
export const DISPATCH_LIST_SUM_LESS_MORE_TO_STORE      = "DISPATCH_LIST_SUM_LESS_MORE_TO_STORE"
export const DISPATCH_VALUE_SUM_LESS_MORE              = "DISPATCH_VALUE_SUM_LESS_MORE"
export const DISPATCH_SUM_LESS_MORE_TO_DB              = "DISPATCH_SUM_LESS_MORE_TO_DB"

export const CONDITION_PRINT_FALSE                           = "CONDITION_PRINT_FALSE"
export const CONDITION_PRINT_TRUE                            = "CONDITION_PRINT_TRUE"
export const GENERAL_TRUE                                    = "GENERAL_TRUE"
export const GENERAL_FALSE                                   = "GENERAL_FALSE"
export const CLASS_TRUE                                      = "CLASS_TRUE"
export const CLASS_FALSE                                     = "CLASS_FALSE"
export const DISPATCH_SET_VALUE_CLASS_OR_STUDENT             = "DISPATCH_SET_VALUE_CLASS_OR_STUDENT"
export const MATERIAL_TRUE                                   = "MATERIAL_TRUE"
export const TOTAL_MATERIAL_TRUE                             = "TOTAL_MATERIAL_TRUE"
export const TOTAL_MATERIAL_FALSE                             = "TOTAL_MATERIAL_FALSE"

export const MATERIAL_FALSE                                  = "MATERIAL_FALSE"

export const DISPATCH_ID_CLASS                               = "DISPATCH_ID_CLASS"

export const OPEN_PAYMENT_LIST                               = "OPEN_PAYMENT_LIST"
export const CLOSE_PAYMENT_LIST                              = "CLOSE_PAYMENT_LIST"
export const DISPATCH_SELECT_PRICE_MODAL_OPEN                = "DISPATCH_SELECT_PRICE_MODAL_OPEN"
export const DISPATCH_SELECT_PRICE_MODAL_CLOSE               = "DISPATCH_SELECT_PRICE_MODAL_CLOSE"
export const DISPATCH_ITEMS_PRICE_TO_STORE                   = "DISPATCH_ITEMS_PRICE_TO_STORE"

export const OPEN_ALERT_FORM                                 = "OPEN_ALERT_FORM"
export const CLOSE_ALERT_FORM                                = "CLOSE_ALERT_FORM"
export const DISPATCH_PRICE_VALUE_TO_STORE                   = "DISPATCH_PRICE_VALUE_TO_STORE"
export const OPEN_PRINT_PAYMENT_LIST                         = "OPEN_PRINT_PAYMENT_LIST"
export const CLOSE_PRINT_PAYMENT_LIST                        = "CLOSE_PRINT_PAYMENT_LIST"
export const OPEN_EXPORT_EXCEL                               = "OPEN_EXPORT_EXCEL"
export const CLOSE_EXPORT_EXCEL                              = "CLOSE_EXPORT_EXCEL"
export const CURRENT_DATE                                    = "CURRENT_DATE"
export const DISPATCH_PRICES_MATERIAL_BY_DATE                = "DISPATCH_PRICES_MATERIAL_BY_DATE"
export const DISPACTH_NAME_CATE_MATERIAL                     = "DISPACTH_NAME_CATE_MATERIAL"  
export const DISPACTH_NAME_TYPE_MATERIAL                     = "DISPACTH_NAME_TYPE_MATERIAL"  
export const MATERIAL_LIST_VIEW_OPEN                         = "MATERIAL_LIST_VIEW_OPEN"
export const TYPE_MATERIAL_LIST_VIEW_OPEN                    = "TYPE_MATERIAL_LIST_VIEW_OPEN"
export const TYPE_MATERIAL_LIST_VIEW_CLOSE                   = "TYPE_MATERIAL_LIST_VIEW_CLOSE"

export const OPEN_PRINT_TOTAL_CHECK_DAY_FOOD                 = "OPEN_PRINT_TOTAL_CHECK_DAY_FOOD"
export const CLOSE_PRINT_TOTAL_CHECK_DAY_FOOD                = "CLOSE_PRINT_TOTAL_CHECK_DAY_FOOD"



export const OPEN_PRINT_TOTAL_MATERIAL = "OPEN_PRINT_TOTAL_MATERIAL"
export const CLOSE_PRINT_TOTAL_MATERIAL = "CLOSE_PRINT_TOTAL_MATERIAL"


export const OPEN_ADD_SUPPLIES                   =  "OPEN_ADD_SUPPLIES"
export const CLOSE_ADD_SUPPLIES                  =  "CLOSE_ADD_SUPPLIES"
export const SAVE_ADD_SUPPLIES                   =  "SAVE_ADD_SUPPLIES"
export const DISPATCH_ITEM_ADD_SUPPLIES_TO_STORE = "DISPATCH_ITEM_ADD_SUPPLIES_TO_STORE"
export const LIST_SUPPLIES_STORE                 = "LIST_SUPPLIES_STORE"
export const DISPATCH_LIST_SUPPLIES_STORE        = "DISPATCH_LIST_SUPPLIES_STORE"

export const OPEN_EDIT_SUPPLIES_COUNT_EAT                  = "OPEN_EDIT_SUPPLIES_COUNT_EAT"
export const CLOSE_EDIT_SUPPLIES_COUNT_EAT                 = "CLOSE_EDIT_SUPPLIES_COUNT_EAT"

export const DISPATCH_EDIT_SUPPLIES_TO_DB        = "DISPATCH_EDIT_SUPPLIES_TO_DB"

export const TOTAL_SUPPLIES_TRUE                 = "TOTAL_SUPPLIES_TRUE"
export const TOTAL_SUPPLIES_FALSE                = "TOTAL_SUPPLIES_FALSE"

export const DISPACTH_NAME_SUPPLIES              = "DISPACTH_NAME_SUPPLIES"
export const DISPATCH_NAME_TYPE_MATERIAL_SUPPLIES= "DISPATCH_NAME_TYPE_MATERIAL_SUPPLIES"
export const SUPPLIES_LIST_VIEW_OPEN             = "SUPPLIES_LIST_VIEW_OPEN"
export const DISPATCH_DATE_INPUT                 = "DISPATCH_DATE_INPUT"

export const PRINT_MATERIAL_TRUE                 = "PRINT_MATERIAL_TRUE"
export const PRINT_MATERIAL_FALSE                = "PRINT_MATERIAL_FALSE"

export const PRINT_SUPPLIES_TRUE                 = "PRINT_SUPPLIES_TRUE"
export const PRINT_SUPPLIES_FALSE                = "PRINT_SUPPLIES_FALSE"

export const OPEN_ADD_REPOSITORY                 = "OPEN_ADD_REPOSITORY"
export const CLOSE_ADD_REPOSITORY                = "CLOSE_ADD_REPOSITORY"
export const DISPACTH_ID_TYPE_MATERIAL           = "DISPACTH_ID_TYPE_MATERIAL"

export const LIST_TYPE_MATERIAL_NEW_ARR          = "LIST_TYPE_MATERIAL_NEW_ARR"

export const DISPATCH_ADD_REPOSITORY             = "DISPATCH_ADD_REPOSITORY"
export const DISPATCH_ADD_REPOSITORY_TO_STORE    = "DISPATCH_ADD_REPOSITORY_TO_STORE"
export const LIST_REPOSITORY_TO_STORE            = "LIST_REPOSITORY_TO_STORE"
export const DISPATCH_LIST_REPOSITORY_TO_STORE   = "DISPATCH_LIST_REPOSITORY_TO_STORE"



export const ALLOW_DELETE_REPOSITORY              = "ALLOW_DELETE_REPOSITORY"
export const DENY_DELETE_REPOSITORY               = "DENY_DELETE_REPOSITORY"

export const OPEN_FORM_ALLOW_DELETE               ="OPEN_FORM_ALLOW_DELETE"
export const CLOSE_FORM_ALLOW_DELETE              ="CLOSE_FORM_ALLOW_DELETE"

export const OPEN_FORM_EDIT_REPOSITORY            = "OPEN_FORM_EDIT_REPOSITORY"
export const CLOSE_FORM_EDIT_REPOSITORY           = "CLOSE_FORM_EDIT_REPOSITORY"

export const DISPATCH_ITEM_EDIT_REPOSITORY        = "DISPATCH_ITEM_EDIT_REPOSITORY"
export const DISPATCH_ITEM_EDIT_SAVE              = "DISPATCH_ITEM_EDIT_SAVE"

export const SHOW_MENU_PRINT_CHECK_EAT_COUNT      = "SHOW_MENU_PRINT_CHECK_EAT_COUNT"
export const HIDE_MENU_PRINT_CHECK_EAT_COUNT      = "HIDE_MENU_PRINT_CHECK_EAT_COUNT"

export const OPEN_CREATE_EXPORT_REPO              = "OPEN_CREATE_EXPORT_REPO"
export const CLOSE_CREATE_EXPORT_REPO             = "CLOSE_CREATE_EXPORT_REPO"

export const DISPATCH_ITEM_EXPORT_REPO            = "DISPATCH_ITEM_EXPORT_REPO"
export const OPEN_PRINT_EXPORT_REPO               = "OPEN_PRINT_EXPORT_REPO"
export const CLOSE_PRINT_EXPORT_REPO              = "CLOSE_PRINT_EXPORT_REPO"

export const OPEN_CREATE_PAYMENT_ORDER            = "OPEN_CREATE_PAYMENT_ORDER"
export const CLOSE_CREATE_PAYMENT_ORDER           = "CLOSE_CREATE_PAYMENT_ORDER"

export const DISPATCH_ITEM_PAYMENT_ORDER          = "DISPATCH_ITEM_PAYMENT_ORDER"
export const OPEN_PRINT_PAYMENT_ORDER             = "OPEN_PRINT_PAYMENT_ORDER"
export const CLOSE_PRINT_PAYMENT_ORDER            = "CLOSE_PRINT_PAYMENT_ORDER"

export const CREATE_MANAGE_REPOSITORY             = "CREATE_MANAGE_REPOSITORY"

export const DISPATCH_NAME_MANAGE_REPO            = "DISPATCH_NAME_MANAGE_REPO"
export const REPO_LIST_VIEW_OPEN                  = "REPO_LIST_VIEW_OPEN"

export const MANAGE_REPO_OPEN                     = "MANAGE_REPO_OPEN"
export const MANAGE_REPO_CLOSE                    = "MANAGE_REPO_CLOSE"

export const PRINT_MANAGE_REPO_TRUE               = "PRINT_MANAGE_REPO_TRUE"
export const PRINT_MANAGE_REPO_FALSE              = "PRINT_MANAGE_REPO_FALSE"

export const OPEN_IMPORT_REPO                     = "OPEN_IMPORT_REPO"
export const CLOSE_IMPORT_REPO                    = "CLOSE_IMPORT_REPO"

export const ADD_IMPORT_TYPE_MATERIAL_TO_DB       = "ADD_IMPORT_TYPE_MATERIAL_TO_DB"
export const DISPATCH_ADD_IMPORT_TO_STORE         = "DISPATCH_ADD_IMPORT_TO_STORE"
export const LIST_IMPORT_TYPE_MATERIAL            = "LIST_IMPORT_TYPE_MATERIAL"
export const DISPATCH_LIST_TYPE_MATERIAL_TO_STORE = "DISPATCH_LIST_TYPE_MATERIAL_TO_STORE"
export const EDIT_IMPORT_REPO                     = "EDIT_IMPORT_REPO"
export const DISPATCH_ARR_TO_RESULT_REPOSITORY    = "DISPATCH_ARR_TO_RESULT_REPOSITORY"
export const DELETE_ITEM_IMPORT_TYPE_MATERIAL     = "DELETE_ITEM_IMPORT_TYPE_MATERIAL"

//export const


export const ADD_BALANCE                          = "ADD_BALANCE"
export const DISPATCH_ITEM_ADD_BALANCE_TO_STORE   = "DISPATCH_ITEM_ADD_BALANCE_TO_STORE"
export const LIST_BALANCE                         = "LIST_BALANCE"
export const DISPATCH_LIST_BALANCE_TO_STORE       = "DISPATCH_LIST_BALANCE_TO_STORE"
export const DISPATCH_EDIT_BALANCE                = "DISPATCH_EDIT_BALANCE"

// export const DISPATCH_OPEN_FORM_REPO_MANAGER_ITEM          = "DISPATCH_OPEN_FORM_REPO_MANAGER_ITEM"
// export const DISPATCH_CLOSE_FORM_REPO_MANAGER_ITEM         = "DISPATCH_CLOSE_FORM_REPO_MANAGER_ITEM"

export const DISPATCH_OPEN_FORM_UPDATE_REPO       = "DISPATCH_OPEN_FORM_UPDATE_REPO"
export const DISPATCH_CLOSE_FORM_UPDATE_REPO      = "DISPATCH_CLOSE_FORM_UPDATE_REPO"
export const DISPATCH_EVENT_AFTER_ADD_BALANCE     = "DISPATCH_EVENT_AFTER_ADD_BALANCE"


export const SHOW_PAYMENT_TOTAL    = "SHOW_PAYMENT_TOTAL"
export const CLOSE_PAYMENT_TOTAL   = "CLOSE_PAYMENT_TOTAL"

export const OPEN_PRINT_PAYMENT_LIST_TOTAL = "OPEN_PRINT_PAYMENT_LIST_TOTAL"
export const CLOSE_PRINT_PAYMENT_LIST_TOTAL = "CLOSE_PRINT_PAYMENT_LIST_TOTAL"

export const OPEN_TOTAL_COUNT_EAT      = "OPEN_TOTAL_COUNT_EAT"
export const CLOSE_TOTAL_COUNT_EAT     = "CLOSE_TOTAL_COUNT_EAT"

export const OPEN_PRINT_TOTAL_COUNT_EAT      = "OPEN_PRINT_TOTAL_COUNT_EAT"
export const CLOSE_PRINT_TOTAL_COUNT_EAT     = "CLOSE_PRINT_TOTAL_COUNT_EAT"

export const OPEN_TOTAL_STUDENT_NOT_EAT  = "OPEN_TOTAL_STUDENT_NOT_EAT"
export const CLOSE_TOTAL_STUDENT_NOT_EAT = "CLOSE_TOTAL_STUDENT_NOT_EAT"

export const OPEN_PRINT_TOTAL_STUDENT_NOT_EAT  = "OPEN_PRINT_TOTAL_STUDENT_NOT_EAT"
export const CLOSE_PRINT_TOTAL_STUDENT_NOT_EAT = "CLOSE_PRINT_TOTAL_STUDENT_NOT_EAT"

export const OPEN_EXPORT_EXCEL_PAYMENT_TOTAL   = "OPEN_EXPORT_EXCEL_PAYMENT_TOTAL"

export const OPEN_EXPORT_EXCEL_TOTAL_COUNT_EAT = "OPEN_EXPORT_EXCEL_TOTAL_COUNT_EAT"

export const OPEN_EXPORT_EXCEL_TOTAL_MATERIAL = "OPEN_EXPORT_EXCEL_TOTAL_MATERIAL"

export const OPEN_EXPORT_EXCEL_IMPORT_REPO = "OPEN_EXPORT_EXCEL_IMPORT_REPO"

export const OPEN_EXPORT_EXCEL_TOTAL_MATERIAL_SUPPLIES = "OPEN_EXPORT_EXCEL_TOTAL_MATERIAL_SUPPLIES"
export const OPEN_EXPORT_EXCEL_TOTAL_STUDENT_NOT_EAT = "OPEN_EXPORT_EXCEL_TOTAL_STUDENT_NOT_EAT"

export const OPEN_EXPORT_EXCEL_MATERIAL = "OPEN_EXPORT_EXCEL_MATERIAL"

export const TYPE_MATERIAL_TRUE    = "TYPE_MATERIAL_TRUE"
export const TYPE_MATERIAL_FALSE   = "TYPE_MATERIAL_FALSE"

export const PRINT_TYPE_MATERIAL_TRUE         = "PRINT_TYPE_MATERIAL_TRUE"
export const PRINT_TYPE_MATERIAL_FALSE        = "PRINT_TYPE_MATERIAL_FALSE"
export const OPEN_EXPORT_EXCEL_TYPE_MATERIAL  = "OPEN_EXPORT_EXCEL_TYPE_MATERIAL"
export const CLOSE_EXPORT_EXCEL_TYPE_MATERIAL = "CLOSE_EXPORT_EXCEL_TYPE_MATERIAL"


export const RESULT_TYPE_MATERIAL_SUPPLIES  = "RESULT_TYPE_MATERIAL_SUPPLIES"

export const OPEN_LIST_CHECK_DAY_FOOD_IN_MONTH   = "OPEN_LIST_CHECK_DAY_FOOD_IN_MONTH"
export const CLOSE_LIST_CHECK_DAY_FOOD_IN_MONTH   = "CLOSE_LIST_CHECK_DAY_FOOD_IN_MONTH"

export const OPEN_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH = "OPEN_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH"
export const CLOSE_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH = "CLOSE_EXPORT_EXCEL_CHECK_DY_FOOD_IN_MONTH"


export const OPEN_BUTTON_EXPORT_PDF                              = "OPEN_BUTTON_EXPORT_PDF"
export const CLOSE_BUTTON_EXPORT_PDF                             = "CLOSE_BUTTON_EXPORT_PDF"

//ket chuyen phieu an thua mam non
export const OPEN_FORM_TRANSFER_LEFT_OVER             = "OPEN_FORM_TRANSFER_LEFT_OVER"
export const CLOSE_FORM_TRANSFER_LEFT_OVER            = "CLOSE_FORM_TRANSFER_LEFT_OVER"
export const DISPATCH_VALUES_TRANSFER_LEFT_OVER       = "DISPATCH_VALUES_TRANSFER_LEFT_OVER" //cac thong tin class, blockclass, id yearschool, userid
export const UPLOAD_TRANSFER_LEFT_OVER                = "UPLOAD_TRANSFER_LEFT_OVER" //thong tin ket chuyen so phieu an thua ten hoc sinh, so phieu an(sum)
export const DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE = "DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE"
export const DISPATCH_GET_LIST_TRANSFER               = "DISPATCH_GET_LIST_TRANSFER"
export const DISPATCH_LIST_TRANSFER_TO_STORE          = "DISPATCH_LIST_TRANSFER_TO_STORE"
export const DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS= "DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS"
export const DELETE_TRANSFER_LEFT_SUCCESS             = "DELETE_TRANSFER_LEFT_SUCCESS"

export const OPEN_FORM_DELETE_TRANSFER_LEFT_OVER     =  "OPEN_FORM_DELETE_TRANSFER_LEFT_OVER" 
export const CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER    =  "CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER" 

////ket chuyen phieu an thua tieu hoc
export const OPEN_FORM_TRANSFER_LEFT_OVER_TH             = "OPEN_FORM_TRANSFER_LEFT_OVER_TH"
export const CLOSE_FORM_TRANSFER_LEFT_OVER_TH           = "CLOSE_FORM_TRANSFER_LEFT_OVER_TH"

export const DISPATCH_VALUES_TRANSFER_LEFT_OVER_TH       = "DISPATCH_VALUES_TRANSFER_LEFT_OVER_TH" //cac thong tin class, blockclass, id yearschool, userid
export const UPLOAD_TRANSFER_LEFT_OVER_TH                = "UPLOAD_TRANSFER_LEFT_OVER_TH" //thong tin ket chuyen so phieu an thua ten hoc sinh, so phieu an(sum)
export const DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE_TH = "DISPATCH_ADD_TRANSFER_LEFT_OVER_TO_STORE_TH"
export const DISPATCH_GET_LIST_TRANSFER_TH               = "DISPATCH_GET_LIST_TRANSFER_TH"
export const DISPATCH_LIST_TRANSFER_TO_STORE_TH          = "DISPATCH_LIST_TRANSFER_TO_STORE_TH"
export const DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS_TH= "DELETE_TRANSFER_LEFT_OVERS_BY_MONTH_CLASS_TH"
export const DELETE_TRANSFER_LEFT_SUCCESS_TH             = "DELETE_TRANSFER_LEFT_SUCCESS_TH"

export const OPEN_FORM_DELETE_TRANSFER_LEFT_OVER_TH     =  "OPEN_FORM_DELETE_TRANSFER_LEFT_OVER_TH" 
export const CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER_TH    =  "CLOSE_FORM_DELETE_TRANSFER_LEFT_OVER_TH" 

//buffet
export const OPEN_FORM_BUFFET  = "OPEN_FORM_BUFFET"
export const CLOSE_FORM_BUFFET = "CLOSE_FORM_BUFFET"

export const ADD_BUFFET_DB        = "ADD_BUFFET_DB"
export const ADD_BUFFET_TO_STORE  = "ADD_BUFFET_TO_STORE"

export const LIST_BUFFET          = "LIST_BUFFET"
export const LIST_BUFFET_TO_STORE = "LIST_BUFFET_TO_STORE"

export const OPEN_UPDATE_BUFFET        = "OPEN_UPDATE_BUFFET"
export const CLOSE_UPDATE_BUFFET       = "CLOSE_UPDATE_BUFFET"

export const UPDATE_BUFFET_DB          = "UPDATE_BUFFET_DB"
export const REMOVE_BUFFET             = "REMOVE_BUFFET"

export const GET_ALL_DB_YEARSHOOL_REPOSITORIES = "GET_ALL_DB_YEARSHOOL_REPOSITORIES"
export const WHEN_LIST_ALL_YEAR_SCHOOL_0       = "WHEN_LIST_ALL_YEAR_SCHOOL_0"
export const WHEN_LIST_ALL_YEAR_SCHOOL_1       = "WHEN_LIST_ALL_YEAR_SCHOOL_1"
export const WHEN_LIST_ALL_YEAR_SCHOOL_MORE_1       = "WHEN_LIST_ALL_YEAR_SCHOOL_MORE_1"


export const OPEN_FORM_PRINT_RECEIPTS         = "OPEN_FORM_PRINT_RECEIPTS"
export const CLOSE_FORM_PRINT_RECEIPTS        = "CLOSE_FORM_PRINT_RECEIPTS"

export const OPEN_FORM_UPDATE_PRINT_RECEIPTS  = "OPEN_FORM_UPDATE_PRINT_RECEIPTS"
export const CLOSE_FORM_UPDATE_PRINT_RECEIPTS = "CLOSE_FORM_UPDATE_PRINT_RECEIPTS"

export const DISPATCH_ITEM_LIST_STUDENT_BY_CLASS_YEARSCHOOL = "DISPATCH_ITEM_LIST_STUDENT_BY_CLASS_YEARSCHOOL"
export const ADD_PRINT_RECEIPTS                             = "ADD_PRINT_RECEIPTS"
export const DISPATCH_ADD_RECEIPTS_TO_STORE                 = "DISPATCH_ADD_RECEIPTS_TO_STORE"
export const GET_RECEIPTS_BY_USERID_IDCLASS_IDYEARSCHOOL    = "GET_RECEIPTS_BY_USERID_IDCLASS_IDYEARSCHOOL"
export const DISPATCH_GET_RECEIPTS_TO_STORE                 = "DISPATCH_GET_RECEIPTS_TO_STORE"
export const UPDATE_RECEIPTS                                = "UPDATE_RECEIPTS"
export const UPDATE_ITEM_RECEIPTS                           = "UPDATE_ITEM_RECEIPTS"

export const OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM        = "OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM"
export const CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM       = "CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_SELECT_ITEM"


export const OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM    = "OPEN_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM"
export const CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM   = "CLOSE_DISPATCH_VALUE_PRINT_RECEIPTS_ALL_SELECT_ITEM"

export const OPEN_FORM_UPDATE_DAILY_TUI         = "OPEN_FORM_UPDATE_DAILY_TUI"
export const CLOSE_FORM_UPDATE_DAILY_TUI        = "CLOSE_FORM_UPDATE_DAILY_TUI"

export const ADD_UPDATE_DAILY_TUI               = "ADD_UPDATE_DAILY_TUI"
export const DISPATCH_ADD_UPDATE_DAILY_TUI_TO_STORE       = "DISPATCH_ADD_UPDATE_DAILY_TUI_TO_STORE"
export const GET_LIST_UPDATE_DAILY_TUI          = "GET_LIST_UPDATE_DAILY_TUI"
export const DISPATCH_LIST_UPDATE_DAILY_TUI     = "DISPATCH_LIST_UPDATE_DAILY_TUI"
export const UPDATE_ITEM_UPDATE_DAILY_TUI       = "UPDATE_ITEM_UPDATE_DAILY_TUI"


export const DISPATCH_SAO_CHEP_TINH_AN          = "DISPATCH_SAO_CHEP_TINH_AN"


export const DONG_BO_THUC_DON_TUAN_MEAL_TABLE_LIST = "DONG_BO_THUC_DON_TUAN_MEAL_TABLE_LIST "


export const ADD_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN    = "ADD_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN"
export const DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN = "DISPATCH_ITEM_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN"
export const UPDATE_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN = "UPDATE_PHAN_BO_NGUYEN_LIEU_NUTRI_TINH_AN"

export const ALERT_RELOGIN = "ALERT_RELOGIN"
